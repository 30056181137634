import React, { useEffect, useState } from 'react';
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import DocumentView from '../../../../Molecules/DocumentView/DocumentView';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
//import "./PageBanner.scss"; 

var classNames = require('classnames');



const DocumentSection = ({ documentDetails, titleSection = false, hasBackground }) => {

  const [dataArr, setDataArr] = useState([]);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'Ko', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  useEffect(() => {
    let tempDataArr = [];
    documentDetails?.content[0]?.files?.forEach((file) => {
      tempDataArr.push({
        name: file?.field_media_document?.description,
        size: `(.pdf ${formatBytes(file?.relationships?.field_media_document?.localFile?.size)})`,
        link: file?.relationships?.field_media_document?.localFile?.publicURL,
        target: '_blank'
      })
    });

    setDataArr(tempDataArr);

  }, [documentDetails]);


  return (
    <>
      {
        titleSection ? (
          <section className={classNames("section_content", 'section_documents')}>
            {
              hasBackground ? (
                <ShapeBackground
                  color='catskill_white'
                  bottomInfinite={true}
                >
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={documentDetails.title}
                      type="arrow"
                    />
                  </div>
                  <div className="wrapper_page">
                    <div className="section_inner_content">
                      {
                        dataArr.length > 0 &&
                        <DocumentView
                          data={dataArr}
                        />
                      }
                    </div>
                  </div>
                </ShapeBackground>
              ) : (
                <>
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={documentDetails.title}
                      type="arrow"
                    />
                  </div>
                  <div className="wrapper_page">
                    <div className="section_inner_content">
                      {
                        dataArr.length > 0 &&
                        <DocumentView
                          data={dataArr}
                        />
                      }
                    </div>
                  </div>
                </>
              )
            }

          </section>
        ) : (
          <div className={classNames("reports_container", "block_h3_content")}>
            <div className="wrapper_page">
              <TitleBlock
                headingTag="h3"
                title={documentDetails?.title}
                color="color_dark_bleu"
              />
              <div className="section_inner_content">
                {
                  dataArr.length > 0 &&
                  <DocumentView
                    data={dataArr}
                  />
                }
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default DocumentSection;
