import React, { useEffect, useState } from 'react';

import BImage from '../../../../assets/images/varlorcol_desktop.png';
import VideoYoutube from '../../../../components/Video/VideoYoutube';
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';
import { getImage, youtube_parser } from '../../../../Utils/Utils';

let classNames = require('classnames');

/*const youtube_parser = (url) => {
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
/* let match = url?.match(regExp);
  return (match && match[7]?.length == 11) ? match[7] : false;
};*/

const VideoSection = ({ videoDetails }) => {
  const [videoData, setVideoData] = useState(videoDetails);
  useEffect(() => {
    setVideoData(videoDetails);
  }, [videoDetails]);
  return (
    <div className={classNames('meetings_video', 'block_h3_content')}>
      <div className="wrapper_page">
        <TitleBlock
          headingTag="h3"
          title={videoDetails.content[0]?.title}
          color="color_dark_bleu"
        />
      </div>
      <div className="wrapper_page_xs">
        <VideoYoutube
          cover={videoDetails.content[0]?.image}
          videoID={youtube_parser(videoDetails.content[0]?.link)}
          obj={{
            height: '390',
            width: '640',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
              controls: 0,
              disablekb: 1,
              rel: 0,
              showinfo: 0
            },
          }}
        />
      </div>
    </div>
  );
};

export default VideoSection;
